import React from 'react'
import { RentComparableFloorPlanItem } from '../../../../generated/graphql'
const numeral = require('numeral')

type Props = {
    floorPlanComparable: RentComparableFloorPlanItem
    titleStyle?: any
    maxRowsPerTable?: number
}

export function RentComparablesFloorplanTable(props: Props) {
    const { floorPlanComparable } = props

    const format = (amount: number) => {
        return numeral(amount).format('0,0')
    }

    return (
        <div className="container">
            <div className="row">
                <div className="cblock__body">
                    <div className={'table-type'}>By Floor Plan:</div>
                    <div className={'table-title'} style={props?.titleStyle || {}}>
                        {floorPlanComparable.title}
                    </div>
                    <table className="table table-borderless table-striped table--smallmobile animatedin in stop">
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Unit type</th>
                                <th>Units</th>
                                <th>SF</th>
                                <th>Eff rent</th>
                                <th className="text-right">rent/sf</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorPlanComparable.properties
                                .sort((a, b) => {
                                    const rsfA = a.sf > 0 ? a.effectiveRent / a.sf : 0
                                    const rsfb = b.sf > 0 ? b.effectiveRent / b.sf : 0
                                    if (rsfA <= rsfb) return 1
                                    if (rsfA > rsfb) return -1
                                })
                                .map((row, index) => {
                                    if (props.maxRowsPerTable != null && index >= props.maxRowsPerTable) return null
                                    return (
                                        <tr key={index}>
                                            <td>{row.title}</td>
                                            <td>{row.type}</td>
                                            <td>{row.units}</td>
                                            <td>{row.sf}</td>
                                            <td>${format(row.effectiveRent)}</td>
                                            <td className={'text-right font-weight-bold'}>
                                                {row.sf > 0 ? `\$${(row.effectiveRent / row.sf).toFixed(2)}` : '-'}
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .table-type {
                    text-transform: uppercase;
                }
                .table-title {
                    text-align: left;
                    font-size: 1.4rem;
                }
                .table {
                    color: white;
                    border: 1px solid rgba(255, 255, 255, 0.45);
                    font-size: 0.8rem;
                    tr {
                        td,
                        th {
                            //min-width: 90px;
                        }
                    }
                    .bold {
                        font-weight: bold;
                    }
                    .top-border {
                        border-top: 1px solid rgba(255, 255, 255, 0.45);
                    }
                }
            `}</style>
        </div>
    )
}
