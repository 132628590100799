import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import numeral from 'numeral'
import { ShowMoreButton } from '../../housing/ShowMoreButton'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDistanceInMiles } from '../../../../../utils/getDistance'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { useRouter } from 'next/router'

type Props = {
    rentComparables: Array<any>
    name: string
    model: any
    unitMix: any[]
    effectiveRent?: number
    rentSf?: number
    location: {
        lat: number
        lng: number
    }
    highlightedRow?: number
    allowDelete?: boolean

    chunkSize?: number
    chunk?: number
    tableStyle?: any
    titleStyle?: any
}

export function RentComparablesTable(props: Props) {
    const { rentComparables, name, model, unitMix, effectiveRent, rentSf, location, highlightedRow, allowDelete } =
        props

    const initialRows = props.chunkSize || 10
    const rowsStep = 10
    const [rows, setRows] = useState<number>(((props.chunk || 0) + 1) * initialRows)

    const {
        tableData,
        totalCompsUnits,
        avgYearBuilt,
        avgOccupancy,
        avgSF,
        avgRent,
        avgPriceStudio,
        avgPrice1Bed,
        avgPrice2Bed,
        avgPrice3Bed,
        avgRentPerSqft,
        avgDistance,
    } = useMemo(() => {
        let tableData = []
        let totalCompsUnits = 0
        let numOfcomps = 0
        let avgYearBuilt = 0
        let avgOccupancy = 0
        let avgSF = 0
        let avgRent = 0
        let avgPriceStudio = 0
        let avgPrice1Bed = 0
        let avgPrice2Bed = 0
        let avgPrice3Bed = 0
        let avgRentPerSqft = 0
        let avgDistance = 0
        let totalCompsSF = 0
        let totalCompsRent = 0

        let numOfcompsStudio = 0
        let numOfcomps1Bed = 0
        let numOfcomps2Bed = 0
        let numOfcomps3Bed = 0

        if (rentComparables) {
            for (const comp of rentComparables) {
                let totalUnits = 0
                let totalSf = 0
                let totalEffectiveRent = 0
                let unitTypes = 0

                let priceStudio = 0
                let price1Bed = 0
                let price2Bed = 0
                let price3Bed = 0
                let dspUnits = 0
                if (comp?.units) {
                    for (const unit of comp?.units) {
                        // if unit number not set threat it as 1 in calc and as 0 to display
                        totalUnits += unit?.unitsCount || 1
                        dspUnits += unit?.unitsCount || 0
                        totalSf += unit?.sf * (unit?.unitsCount || 1)
                        totalEffectiveRent += unit?.effectiveAverageRent * (unit?.unitsCount || 1)

                        if (unit?.type) {
                            const bedrooms = unit?.type?.replace(/\s/g, '').split('/')?.[0] || ''
                            switch (+bedrooms[0]) {
                                case 0:
                                    priceStudio = unit?.effectiveAverageRent
                                    break
                                case 1:
                                    price1Bed = unit?.effectiveAverageRent
                                    break
                                case 2:
                                    price2Bed = unit?.effectiveAverageRent
                                    break
                                case 3:
                                    price3Bed = unit?.effectiveAverageRent
                                    break
                            }
                        }
                    }
                }
                dspUnits = dspUnits || comp?.totalUnits
                numOfcomps++
                totalCompsUnits += dspUnits
                avgYearBuilt += comp?.yearBuilt
                avgOccupancy += comp?.occupancy ? comp?.occupancy / 100 : 0
                totalCompsRent += totalEffectiveRent || comp?.avgUnitRent * dspUnits

                totalCompsSF += totalSf
                avgDistance += getDistanceInMiles(comp?.location, location)

                if (priceStudio > 0) {
                    numOfcompsStudio++
                    avgPriceStudio += priceStudio
                }

                if (price1Bed > 0) {
                    numOfcomps1Bed++
                    avgPrice1Bed += price1Bed
                }

                if (price2Bed > 0) {
                    numOfcomps2Bed++
                    avgPrice2Bed += price2Bed
                }

                if (price3Bed > 0) {
                    numOfcomps3Bed++
                    avgPrice3Bed += price3Bed
                }

                tableData.push({
                    id: comp?.id,
                    title: comp?.title,
                    units: dspUnits,
                    yearBuilt: comp?.yearBuilt,
                    yearRenovated: comp?.yearRenovated > 0 && comp?.yearRenovated,
                    buildingClass: comp?.buildingClass,
                    occupancy: comp?.occupancy,
                    avgUnitSf: totalSf / totalUnits,
                    effectiveRent: totalEffectiveRent / dspUnits || comp?.avgUnitRent,
                    priceStudio: priceStudio,
                    price1Bed: price1Bed,
                    price2Bed: price2Bed,
                    price3Bed: price3Bed,
                    avrRentSF: totalEffectiveRent / totalSf,
                    location: comp?.location,
                    distance: getDistanceInMiles(comp?.location, location),
                })
            }
        }
        tableData.sort((a, b) => {
            if (a.distance < b.distance) return -1
            else if (a.distance > b.distance) return 1
            return 0
        })
        avgYearBuilt = avgYearBuilt > 0 ? avgYearBuilt / numOfcomps : 0
        avgOccupancy = avgOccupancy > 0 ? avgOccupancy / numOfcomps : 0
        avgSF = totalCompsUnits > 0 ? totalCompsSF / totalCompsUnits : 0
        avgRent = totalCompsUnits > 0 ? totalCompsRent / totalCompsUnits : 0

        avgPriceStudio = numOfcompsStudio > 0 ? avgPriceStudio / numOfcompsStudio : 0
        avgPrice1Bed = numOfcomps1Bed > 0 ? avgPrice1Bed / numOfcomps1Bed : 0
        avgPrice2Bed = numOfcomps2Bed > 0 ? avgPrice2Bed / numOfcomps2Bed : 0
        avgPrice3Bed = numOfcomps3Bed > 0 ? avgPrice3Bed / numOfcomps3Bed : 0

        avgRentPerSqft = totalCompsSF > 0 ? totalCompsRent / totalCompsSF : 0
        avgDistance = avgDistance > 0 ? avgDistance / numOfcomps : 0
        return {
            tableData,
            totalCompsUnits,
            avgYearBuilt,
            avgOccupancy,
            avgSF,
            avgRent,
            avgPriceStudio,
            avgPrice1Bed,
            avgPrice2Bed,
            avgPrice3Bed,
            avgRentPerSqft,
            avgDistance,
        }
    }, [rentComparables])

    const currentUnits = useMemo(() => {
        const out = {
            avgSf: 0,
            priceStudio: 0,
            price1Bed: 0,
            price2Bed: 0,
            price3Bed: 0,
            avgRentSF: 0,
            effectiveRent: 0,
        }
        let totalSf = 0
        let totalUnits = 0
        if (unitMix) {
            let studioUnit = 0
            let bed1Unit = 0
            let bed2Unit = 0
            let bed3Unit = 0
            let totalRent = 0
            for (const unit of unitMix) {
                totalUnits += unit?.numofunits || 0
                totalSf += (unit?.unitsize || 0) * (unit?.numofunits || 0)
                totalRent += unit?.rentprice * (unit?.numofunits || 0)

                switch (+unit.bedrooms) {
                    case 0:
                        out.priceStudio += unit?.rentprice * unit?.numofunits || 0
                        studioUnit += unit?.numofunits || 0
                        break
                    case 1:
                        out.price1Bed += unit?.rentprice * unit?.numofunits || 0
                        bed1Unit += unit?.numofunits || 0
                        break
                    case 2:
                        out.price2Bed += unit?.rentprice * unit?.numofunits || 0
                        bed2Unit += unit?.numofunits || 0
                        break
                    case 3:
                        out.price3Bed += unit?.rentprice * unit?.numofunits || 0
                        bed3Unit += unit?.numofunits || 0
                        break
                }
            }
            if (studioUnit) out.priceStudio = Math.round(out.priceStudio / studioUnit)
            if (bed1Unit) out.price1Bed = Math.round(out.price1Bed / bed1Unit)
            if (bed2Unit) out.price2Bed = Math.round(out.price2Bed / bed2Unit)
            if (bed3Unit) out.price3Bed = Math.round(out.price3Bed / bed3Unit)
            if (totalSf) out.avgRentSF = +(totalRent / totalSf).toFixed(2)
            if (totalUnits) out.effectiveRent = +(totalRent / totalUnits).toFixed(2)
        } else {
            if (model.totalUnitsFromDataProvider) totalUnits = model.totalUnitsFromDataProvider
            if (model.totalSQFTFromDataProvider) totalSf = model.totalSQFTFromDataProvider
        }

        if (totalUnits > 0) {
            out.avgSf = totalSf / totalUnits
        }

        return out
    }, [unitMix])

    const sagaName = 'fullProjectComparables'
    const deleteComp = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault()
            // console.log('deleteComp', e.currentTarget, e.currentTarget.attributes)
            const compIndex = parseInt(e.currentTarget.attributes.getNamedItem('data-src').value)
            //console.log('tableData', tableData)
            //console.log('delete', compIndex, rentComparables)

            if (window.confirm('Are you sure you wish to delete this item?')) {
                //console.log('delete ', compIndex, rentComparables[compIndex])
                /*
                const newRentComparables = rentComparables
                    .slice(0, compIndex)
                    .concat(rentComparables.slice(compIndex + 1, rentComparables.length))

                 */

                SagaHelper.run([sagaName, 'deleteComparable'], { comparableId: compIndex }).then((res) => {
                    //console.log('db deleted ')
                    window.location.reload(false)
                })
            } else {
                console.log('dont delete ', compIndex)
            }
        },
        [props],
    )

    const router = useRouter()
    const { printmode } = useMemo(() => {
        let printmode = false
        if (router.asPath.indexOf('printmode=true') > -1) {
            printmode = true
        }
        return { printmode }
    }, [router])
    return (
        <div className="row w-100 my-5 markPdfPageBreak">
            {/*<h3>Rent Comparables</h3>*/}
            <div className="w-100 text-right">{/*{address} - View of {city}*/}</div>
            <table
                className="table table-borderless table-striped table--large table--smallmobile my-4 onlyPrint"
                style={
                    props.tableStyle ? props.tableStyle : printmode ? { marginLeft: '-85px' } : { marginLeft: '-85px' }
                }
            >
                <thead>
                    <tr>
                        <th></th>
                        <th>Property Name/Address</th>
                        <th>Units</th>
                        <th>Year Built</th>
                        <th>Year renovated</th>
                        <th>Building Class</th>
                        <th>Occupancy</th>
                        <th>Avf SF</th>
                        <th>Eff. rent</th>
                        <th>Studio</th>
                        <th>1 Bed</th>
                        <th>2 Bed</th>
                        <th>3 Bed</th>
                        <th>Rent/SF</th>
                        <th>Distance</th>
                        {allowDelete && <th>Delete</th>}
                    </tr>
                </thead>
                <tbody>
                    <tr className={'blue-row'}>
                        <td>
                            <FontAwesomeIcon icon={faStar} width={12} height={12} style={{ color: 'red' }} />
                        </td>
                        <td>{name}</td>
                        <td>
                            {model?.numberOfUnits
                                ? model?.numberOfUnits
                                : model.totalUnitsFromDataProvider != null
                                ? model.totalUnitsFromDataProvider
                                : 0}
                        </td>
                        <td>{model?.yearBuilt}</td>
                        <td>{model?.yearRenovated}</td>
                        <td>{model?.buildingClass}</td>
                        <td></td>
                        <td>{numeral(currentUnits.avgSf).format('0,0.[00]')}</td>
                        <td>{numeral(currentUnits.effectiveRent).format('$0,0')}</td>
                        <td>
                            {currentUnits?.priceStudio ? numeral(currentUnits.priceStudio).format('$0,0.[00]') : ''}
                        </td>
                        <td>{currentUnits?.price1Bed ? numeral(currentUnits.price1Bed).format('$0,0.[00]') : ''}</td>
                        <td>{currentUnits?.price2Bed ? numeral(currentUnits.price2Bed).format('$0,0.[00]') : ''}</td>
                        <td>{currentUnits?.price3Bed ? numeral(currentUnits.price3Bed).format('$0,0.[00]') : ''}</td>
                        <td>{numeral(currentUnits.avgRentSF).format('$0,0.[00]')}</td>
                        {allowDelete && <td></td>}
                        <td></td>
                    </tr>
                    {tableData?.map((comp, i) => {
                        if (i < (props.chunk || 0) * initialRows) return null
                        if (i >= rows) {
                            return null
                        }
                        let rentSF = comp?.avrRentSF ? numeral(comp?.avrRentSF).format('$0,0.[00]') : ''
                        rentSF = rentSF == '$NaN' ? '-' : rentSF
                        return (
                            <tr key={i} className={highlightedRow == i ? 'highlight' : ''}>
                                <td>{i + 1}</td>
                                <td>{comp?.title}</td>
                                <td>{comp?.units ? comp?.units : ''}</td>
                                <td>{comp?.yearBuilt}</td>
                                <td>{comp?.yearRenovated}</td>
                                <td>{comp?.buildingClass}</td>
                                <td>{comp?.occupancy ? numeral(comp?.occupancy / 100).format('%0.[00]') : ''}</td>
                                <td>{comp?.avgUnitSf ? numeral(comp?.avgUnitSf).format('0,0') : ''}</td>
                                <td>{comp?.effectiveRent ? numeral(comp?.effectiveRent).format('$0,0') : ''}</td>
                                <td>{comp?.priceStudio ? numeral(comp?.priceStudio).format('$0,0') : ''}</td>
                                <td>{comp?.price1Bed ? numeral(comp?.price1Bed).format('$0,0') : ''}</td>
                                <td>{comp?.price2Bed ? numeral(comp?.price2Bed).format('$0,0') : ''}</td>
                                <td>{comp?.price3Bed ? numeral(comp?.price3Bed).format('$0,0') : ''}</td>
                                <td>{rentSF}</td>
                                <td>{numeral(comp?.distance).format('0,0.[00]')} miles</td>
                                {allowDelete && (
                                    <td>
                                        <div className="delete" onClick={deleteComp} data-src={'' + comp?.id}>
                                            <a className="deleteAncor1" href="#">
                                                <i className="material-icons">delete</i>
                                            </a>
                                        </div>
                                    </td>
                                )}
                            </tr>
                        )
                    })}
                    <tr className={'blue-row'}>
                        <td>
                            <FontAwesomeIcon icon={faStar} width={12} height={12} style={{ color: 'red' }} />
                        </td>
                        <td>Total / Avg.</td>
                        <td>{totalCompsUnits}</td>
                        <td>{avgYearBuilt > 0 ? numeral(avgYearBuilt).format('0') : ''}</td>
                        <td></td>
                        <td></td>
                        <td>{avgOccupancy > 0 ? numeral(avgOccupancy).format('%0.[00]') : ''}</td>
                        <td>{avgSF > 0 ? numeral(avgSF).format('0,0') : ''}</td>
                        <td>{avgRent > 0 ? numeral(avgRent).format('$0,0') : ''}</td>

                        <td>{avgPriceStudio > 0 ? numeral(avgPriceStudio).format('$0,0') : ''}</td>
                        <td>{avgPrice1Bed > 0 ? numeral(avgPrice1Bed).format('$0,0') : ''}</td>
                        <td>{avgPrice2Bed > 0 ? numeral(avgPrice2Bed).format('$0,0') : ''}</td>
                        <td>{avgPrice3Bed > 0 ? numeral(avgPrice3Bed).format('$0,0') : ''}</td>

                        <td>{avgRentPerSqft > 0 ? numeral(avgRentPerSqft).format('$0,0.[00]') : ''}</td>
                        <td>{avgDistance > 0 ? numeral(avgDistance).format('0,0.[00]') + ' miles' : ''} </td>
                        {allowDelete && <td></td>}
                    </tr>
                </tbody>
            </table>
            {!printmode && props.chunk == null && (
                <ShowMoreButton
                    showMore={(e) => {
                        e.preventDefault()
                        setRows((rows) => rows + rowsStep)
                    }}
                    showLess={(e) => {
                        e.preventDefault()
                        setRows((rows) => rows - rowsStep)
                    }}
                    showMoreVisible={rentComparables?.length > rows}
                    showLessVisible={rows > initialRows}
                />
            )}

            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                tr {
                    transition: all linear 0.2s;
                    cursor: pointer;
                    //&:hover {
                    //    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
                    //}
                    &.highlight {
                        box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
                    }
                }
                .blue-row {
                    td {
                        color: $blue !important;
                    }
                }
                .deleteAncor1,
                .deleteAncor1:hover,
                .deleteAncor1:focus {
                    color: inherit;
                    text-decoration: underline;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    color: red;
                }
                .deleteAncor1 {
                    color: darkred;
                    text-decoration: none;
                    background-color: transparent;
                }
                .deleteAncor1 i {
                    font-size: 18px;
                    color: darkred;
                }
                @media print {
                    .onlyPrint {
                        font-size: 12px;
                    }
                }
                /* fallback */
                @font-face {
                    font-family: 'Material Icons';
                    font-style: normal;
                    font-weight: 400;
                    src: url(https://fonts.gstatic.com/s/materialicons/v99/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
                        format('woff2');
                }
                /* fallback */
                @font-face {
                    font-family: 'Material Icons Outlined';
                    font-style: normal;
                    font-weight: 400;
                    src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v76/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
                        format('woff2');
                }

                .material-icons {
                    font-family: 'Material Icons', serif;
                    font-weight: normal;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }

                .material-icons-outlined {
                    font-family: 'Material Icons Outlined', serif;
                    font-weight: normal;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }
            `}</style>
        </div>
    )
}
