import React from 'react'
import { RentComparablePropertyItem } from '../../../../generated/graphql'
const numeral = require('numeral')

type Props = {
    propertyComparable: RentComparablePropertyItem
    maxRowsPerTable?: number
    titleStyle?: any
}

export function RentComparablesPropertyTable(props: Props) {
    const { propertyComparable } = props

    const rentPerSqFt = (rent: number, sf: number) => {
        const rentPerSqFt = rent / sf
        return sf > 0 ? '$' + rentPerSqFt.toFixed(2) : '-'
    }

    const total = (fieldName: string) => {
        let total = 0
        for (const unit of propertyComparable.units) {
            total += unit[fieldName]
        }
        return total
    }

    const average = (fieldName: string) => {
        let total = 0
        let items = 0
        for (const unit of propertyComparable.units) {
            items++
            total += unit[fieldName]
        }
        return total / items
    }

    const averagePerSqFt = (fieldName: string) => {
        let total = 0
        let items = 0
        for (const unit of propertyComparable.units) {
            items++
            total += unit[fieldName] / unit.sf
        }
        return total / items
    }

    const format = (amount: number) => {
        return numeral(amount).format('0,0')
    }

    const formatDouble = (amount: number) => {
        if (isNaN(amount)) return '-'
        const f = numeral(amount).format('$0,0.00')
        return f == '$NaN' ? '-' : f
    }

    return (
        <div className="container">
            <div className="row">
                <div className="cblock__body">
                    <div className={'table-type'}>By Property:</div>
                    <div className={'table-title'}>{propertyComparable.title}</div>
                    <div className={'table-address'}>{propertyComparable.address}</div>
                    <div className={'table-subtitle'} style={props?.titleStyle || {}}>
                        <div>Occupancy: {propertyComparable.occupancy}% </div>
                        <div>Year built: {propertyComparable.yearBuilt}</div>
                    </div>
                    <table className="table table-borderless table-striped table--smallmobile animatedin in stop">
                        <thead>
                            <tr>
                                <th />
                                <th />
                                <th />
                                <th colSpan={2} className={'text-center'}>
                                    Market
                                </th>
                                <th colSpan={2} className={'text-center'}>
                                    Effective
                                </th>
                            </tr>
                            <tr>
                                <th>Unit type</th>
                                <th>Units</th>
                                <th>SF</th>
                                <th>Avg rent</th>
                                <th>rent/sf</th>
                                <th>Avg rent</th>
                                <th className="text-right">rent/sf</th>
                            </tr>
                        </thead>
                        <tbody>
                            {propertyComparable.units.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row.type}</td>
                                        <td>{row.unitsCount}</td>
                                        <td>{row.sf}</td>
                                        <td>${format(row.marketAverageRent)}</td>
                                        <td>{rentPerSqFt(row.marketAverageRent, row.sf)}</td>
                                        <td>${format(row.effectiveAverageRent)}</td>
                                        <td className={'text-right font-weight-bold'}>
                                            {rentPerSqFt(row.effectiveAverageRent, row.sf)}
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr className={'top-border'}>
                                <td>TOTAL/AVG</td>
                                <td>{total('unitsCount')}</td>
                                <td>{format(average('sf'))}</td>
                                <td>${format(average('marketAverageRent'))}</td>
                                <td>{formatDouble(averagePerSqFt('marketAverageRent'))}</td>
                                <td>${format(average('effectiveAverageRent'))}</td>
                                <td className={'text-right font-weight-bold'}>
                                    {formatDouble(averagePerSqFt('effectiveAverageRent'))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/*language=scss*/}
            <style jsx>{`
                .table-type {
                    text-transform: uppercase;
                }
                .table-title {
                    text-align: left;
                    font-size: 1.4rem;
                }
                .table-address {
                    font-size: 0.8rem;
                }
                .table-subtitle {
                    display: flex;
                    justify-content: space-between;
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 0.8rem;
                }
                .table {
                    color: white;
                    font-size: 0.8rem;
                    border: 1px solid rgba(255, 255, 255, 0.45);
                    tr {
                        //display: flex;
                        td,
                        th {
                            //min-width: 90px;
                            &:nth-of-type(1) {
                                //flex-grow: 2;
                            }
                        }
                    }
                    .bold {
                        font-weight: bold;
                    }
                    .top-border {
                        border-top: 1px solid rgba(255, 255, 255, 0.45);
                    }
                }
            `}</style>
        </div>
    )
}
