import React, { PropsWithChildren } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'

export const mapApiKey = process.env.GOOGLE_MAPS_API_KEY

const HocMap = withScriptjs(
    withGoogleMap((props: any) => {
        return <GoogleMap {...props}>{props.children}</GoogleMap>
    }),
)

export const MapAdapter = (props: PropsWithChildren<any>) => {
    return (
        <HocMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&v=quarterly.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: props?.height ? `${props.height}px` : `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            {...props}
        >
            {props.children}
        </HocMap>
    )
}
