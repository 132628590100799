// eslint-disable-next-line @typescript-eslint/naming-convention
export const CHART_COLOR = Object.freeze({
    DEFAULT: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
    BLUE: ['#3F51B5', '#5653FE', '#2983FF', '#bbf2ff', '#81D4FA'],
    BLUE_WHITE: ['#3F51B5', '#b8b7ff', '#aaaaaa', '#ffffff'],
    RED: ['#dd0037', '#5653FE', '#a631c1'],
    APEX_CHARTS_PALETTE_2: ['#3F51B5', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800'],
    APEX_CHARTS_PALETTE_7: ['#D7263D', '#1B998B', '#2E294E', '#F46036', '#E2C044'],
    APEX_CHARTS_PALETTE_10: ['#A300D6', '#7D02EB', '#5653FE', '#2983FF', '#00B1F2'],
    APEX_CHARTS_PALETTE_11: ['#5791FF', '#dc82cf', '#aa7be6', '#AF6288', '#985f84', '#e0b0be', '#8666af', '#C9767B'],
    APEX_CHARTS_PALETTE_12: ['#5791FF', '#5783d6', '#dc82cf', '#50BEB7', '#aa7be6', '#ebe667', '#CE8488', '#c100a5'],
    APEX_DONUT_PALETTE: ['#7FABFF', '#DC82CF', '#aa7be6', '#AF6288', '#985f84', '#e0b0be', '#8666af', '#C9767B'],
    LENDER_DASHBOARD_PALETTE: ['#5891FE', '#C05E7A', '#D66CC7', '#985f84', '#e0b0be', '#8666af', '#C9767B'],
})
