import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { ShowMoreButton } from '../../housing/ShowMoreButton'
import numeral from 'numeral'

type Props = {
    comparables: any[]
    name: string
    model: any
    location: {
        lat: number
        lng: number
    }
    highlightedRow?: number
    allowDelete?: boolean

    chunkSize?: number
    chunk?: number
    tableStyle?: any
    titleStyle?: any
}

import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDistanceInMiles } from '../../../../../utils/getDistance'
import { SagaHelper } from '../../../../../store/sagaHelper'
import { useRouter } from 'next/router'

export const SaleComparableTable = (props: Props) => {
    const { comparables, name, model, location, highlightedRow, allowDelete } = props
    //console.log('allowDelete', allowDelete)
    const router = useRouter()
    const { printmode } = useMemo(() => {
        let printmode = false
        if (router.asPath.indexOf('printmode=true') > -1) {
            printmode = true
        }
        return { printmode }
    }, [router])

    const initialRows = printmode ? 200 : 10
    const rowsStep = 10

    const [rows, setRows] = useState<number>(((props.chunk || 0) + 1) * initialRows)

    const { fileteredComps, totalCompsUnits, avgYearBuilt, avgSalePerSqft, avgDistance } = useMemo(() => {
        const fileteredComps = comparables?.filter((item) => item.isSaleComp == '1')
        let totalCompsUnits = 0
        let numOfcomps = 0
        let avgYearBuilt = 0
        let avgDistance = 0
        let totalCompsSF = 0
        let totalCompsSale = 0
        let avgSalePerSqft = 0
        let compsWithSalePrice = 0
        if (fileteredComps) {
            for (const comp of fileteredComps) {
                comp.distance = getDistanceInMiles(comp?.data?.location, location)
                avgDistance += comp.distance
                numOfcomps++
                totalCompsUnits += parseInt(comp.numofunits)
                avgYearBuilt += parseInt(comp.data?.building?.yearBuilt)

                let salePrice = 0
                if (comp?.data?.saleDetails?.price) {
                    salePrice = comp?.data?.saleDetails?.price
                } else if (comp?.unitsaleprice) {
                    salePrice = comp?.unitsaleprice * comp?.numofunits
                }
                // console.log(item)
                let priceSF = numeral(salePrice / +comp?.size).format('$0,0.[00]')
                priceSF = priceSF == '$NaN' ? '' : priceSF
                let priceUnit = 0
                if (comp?.unitsaleprice) {
                    priceUnit = comp?.unitsaleprice
                } else if (+comp?.numofunits > 0) {
                    priceUnit = salePrice / +comp?.numofunits
                }

                if (salePrice > 0 && comp?.size > 0) {
                    compsWithSalePrice++
                    totalCompsSale += salePrice
                    totalCompsSF += +comp?.size
                }
            }
            fileteredComps.sort((a, b) => {
                if (a.distance < b.distance) return -1
                else if (a.distance > b.distance) return 1
                return 0
            })
        }
        avgYearBuilt = avgYearBuilt > 0 ? avgYearBuilt / numOfcomps : 0
        avgSalePerSqft = totalCompsSF > 0 ? totalCompsSale / totalCompsSF : 0
        avgDistance = avgDistance > 0 ? avgDistance / numOfcomps : 0
        return { fileteredComps, totalCompsUnits, avgYearBuilt, avgSalePerSqft, avgDistance }
    }, [comparables])

    const sagaName = 'fullProjectComparables'
    const deleteComp = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault()
            //console.log('deleteComp', e.currentTarget, e.currentTarget.attributes.getNamedItem('data-src').value)
            const compIndex = parseInt(e.currentTarget.attributes.getNamedItem('data-src').value)
            if (window.confirm('Are you sure you wish to delete this item?')) {
                console.log('delete ', compIndex)
                /*
                const newRentComparables = rentComparables
                    .slice(0, compIndex)
                    .concat(rentComparables.slice(compIndex + 1, rentComparables.length))

                 */

                SagaHelper.run([sagaName, 'deleteComparable'], {
                    comparableId: compIndex,
                }).then((res) => {
                    window.location.reload(false)
                })
            } else {
                console.log('dont delete ', compIndex)
            }
        },
        [props],
    )
    return (
        <>
            <div className={'popup'}>Deleting Comparable</div>
            <div className="row w-100 my-5 markPdfPageBreak">
                {/*<h3>Sale Comparables</h3>*/}
                <table
                    className="table table-borderless table-striped table--large table--smallmobile my-4"
                    style={props?.tableStyle || {}}
                >
                    <thead>
                        <tr>
                            <th></th>
                            <th>Property</th>
                            <th># units</th>
                            <th>Year built</th>
                            <th>Sale Date</th>
                            <th>Year renovated</th>
                            <th>Building Class</th>
                            <th>Sales price</th>
                            <th>price per unit</th>
                            <th>price per sf</th>
                            <th>cap rate</th>
                            <th>distance</th>
                            {allowDelete && <th>Delete</th>}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={'blue-row'}>
                            <td>
                                <FontAwesomeIcon icon={faStar} width={12} height={12} style={{ color: 'red' }} />
                            </td>
                            <td>{name}</td>
                            <td>
                                {model?.numberOfUnits
                                    ? model?.numberOfUnits
                                    : model.totalUnitsFromDataProvider != null
                                    ? model.totalUnitsFromDataProvider
                                    : 0}
                            </td>
                            <td>{model?.yearBuilt}</td>
                            <td>-</td>
                            <td>{model?.yearRenovated || ''}</td>
                            <td>{model?.buildingClass}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            {allowDelete && <td></td>}
                        </tr>
                        {fileteredComps?.map((item, ind) => {
                            if (ind < (props.chunk || 0) * initialRows) return null
                            if (ind >= rows) {
                                return null
                            }
                            let salePrice = 0
                            if (item?.data?.saleDetails?.price) {
                                salePrice = item?.data?.saleDetails?.price
                            } else if (item?.unitsaleprice) {
                                salePrice = item?.unitsaleprice * item?.numofunits
                            }
                            // console.log(item)
                            let priceSF = numeral(salePrice / +item?.size).format('$0,0.[00]')
                            priceSF = priceSF == '$NaN' ? '' : priceSF
                            let priceUnit = 0
                            if (item?.unitsaleprice) {
                                priceUnit = item?.unitsaleprice
                            } else if (+item?.numofunits > 0) {
                                priceUnit = salePrice / +item?.numofunits
                            }
                            return (
                                <tr key={ind} className={highlightedRow == ind ? 'highlight' : ''}>
                                    <td>{ind + 1}</td>
                                    <td>{item?.name || ''}</td>
                                    <td>{+item?.numofunits || ''}</td>
                                    <td>{item?.data?.building?.yearBuilt || ''}</td>
                                    <td>{item?.yearlastsale}</td>
                                    <td>{item?.data?.building?.yearRenovated || ''}</td>
                                    <td>{item?.data?.building?.buildingClass || ''}</td>
                                    <td>{numeral(salePrice).format('$0,0')}</td>
                                    <td>{numeral(priceUnit).format('$0,0')}</td>
                                    <td>{priceSF}</td>
                                    <td>
                                        {item?.estimatedCapRate !== '0.00' &&
                                            numeral(item?.estimatedCapRate / 100).format('%0.[00]')}
                                    </td>
                                    <td>{numeral(item?.distance).format('0,0.[00]')} miles</td>
                                    {allowDelete && (
                                        <td>
                                            <div className="delete" onClick={deleteComp} data-src={item.id}>
                                                <a className="deleteAncor1" href="#">
                                                    <i className="material-icons">delete</i>
                                                </a>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                        <tr className={'blue-row'}>
                            <td>
                                <FontAwesomeIcon icon={faStar} width={12} height={12} style={{ color: 'red' }} />
                            </td>
                            <td>Total / Avg.</td>
                            <td>{totalCompsUnits}</td>
                            <td>{avgYearBuilt > 0 ? numeral(avgYearBuilt).format('0') : ''}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{avgSalePerSqft > 0 ? numeral(avgSalePerSqft).format('$0,0.[00]') : '-'}</td>
                            <td>-</td>
                            <td>{avgDistance > 0 ? numeral(avgDistance).format('0,0.[00]') + ' miles' : ''} </td>
                            {allowDelete && <td></td>}
                        </tr>
                    </tbody>
                </table>
                {!printmode && props.chunk == null && (
                    <ShowMoreButton
                        showMore={(e) => {
                            e.preventDefault()
                            setRows((rows) => rows + rowsStep)
                        }}
                        showLess={(e) => {
                            e.preventDefault()
                            setRows((rows) => rows - rowsStep)
                        }}
                        showMoreVisible={comparables?.length > rows}
                        showLessVisible={rows > initialRows}
                    />
                )}
            </div>
            {/*language=scss*/}
            <style jsx>{`
                @import './src/scss/colors.scss';
                tr {
                    transition: all linear 0.2s;
                    cursor: pointer;
                    //&:hover {
                    //    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
                    //}
                    &.highlight {
                        box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
                    }
                }
                .blue-row {
                    td {
                        color: $blue !important;
                    }
                }
                .deleteAncor1,
                .deleteAncor1:hover,
                .deleteAncor1:focus {
                    color: inherit;
                    text-decoration: underline;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    color: red;
                }
                .deleteAncor1 {
                    color: darkred;
                    text-decoration: none;
                    background-color: transparent;
                }
                .deleteAncor1 i {
                    font-size: 18px;
                    color: darkred;
                }
                /* fallback */
                @font-face {
                    font-family: 'Material Icons';
                    font-style: normal;
                    font-weight: 400;
                    src: url(https://fonts.gstatic.com/s/materialicons/v99/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
                        format('woff2');
                }
                /* fallback */
                @font-face {
                    font-family: 'Material Icons Outlined';
                    font-style: normal;
                    font-weight: 400;
                    src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v76/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
                        format('woff2');
                }

                .material-icons {
                    font-family: 'Material Icons';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }

                .material-icons-outlined {
                    font-family: 'Material Icons Outlined';
                    font-weight: normal;
                    font-style: normal;
                    font-size: 24px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }
                .popup {
                    display: none;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    width: 500px;
                    height: 500px;
                    color: blue;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 500px;
                    font-size: 50px;
                }
            `}</style>
        </>
    )
}
