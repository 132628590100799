import React, { useCallback, useState } from 'react'
import { MapAdapter } from '../../../defaultElements/MapAdapter'
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel'
const { InfoWindow } = require('react-google-maps')
import numeral from 'numeral'
import { useRouter } from 'next/router'

type Location = {
    lat: number
    lng: number
}

type Props = {
    address: string
    location: Location
    markers: Array<{ name: string; address: string; location: Location; img: string; distance: number }>
    setHighlightedIndex?: (number) => void
    projectId: number
}

export const ComparablesMap = (props: Props) => {
    const { location, address, markers, setHighlightedIndex, projectId } = props
    const [markerPopupVisible, setMarkerPopupVisible] = useState<boolean>(false)
    const [markerPopupLocation, setMarkerPopupLocation] = useState<{ lat: number; lng: number } | null>(null)
    const [markerPopupContent, setMarkerPopupContent] = useState(null)

    const setMarkerPopupData = useCallback((item) => {
        let itemUrl = null
        if (item?.image?.includes('https://')) {
            itemUrl = item?.image
        } else if (item?.image?.match(/^\/api/)) {
            itemUrl = item?.image
        } else if (item?.image) {
            itemUrl = `/api/v1/uploaded_files/${projectId}/${item.image}`
        } else {
            itemUrl = '/_img/house_icon.png'
        }

        const content = (
            <div className="marker-popup-wrapper">
                <div className={'col-4 left'}>
                    <img src={itemUrl} alt={'image'} />
                </div>
                <div className={'col-8 right'}>
                    {item?.name && <div className={'regular-text'}>{item?.name}</div>}
                    {item.address && <div className={'regular-text'}>{item.address}</div>}
                    {item?.salePrice && (
                        <div className={'main-text'}>{numeral(item?.salePrice).format('$0,0')} - SOLD</div>
                    )}
                    {item?.priceSf && (
                        <div className={'regular-text'}>{numeral(item?.priceSf).format('$0,0.[00]')} per SF</div>
                    )}
                    {item?.numberOfUnits && <div className={'regular-text'}>{item?.numberOfUnits} Units</div>}
                    {item?.yearBuilt && <div className={'regular-text'}>{item?.yearBuilt} Year Built</div>}

                    {item.distance && (
                        <div className={'regular-text'}>Distance {numeral(item.distance).format('0,0.[00]')} miles</div>
                    )}
                </div>
                {/*language=scss*/}
                <style global jsx>{`
                    @import './src/scss/colors.scss';
                    .marker-popup-wrapper {
                        max-width: 300px;
                        width: 300px;
                        display: flex;
                        .left {
                            display: flex;
                            align-items: center;
                            img {
                                height: 100%;
                                max-height: 75px;
                                max-width: 100%;
                                width: auto;
                                margin-right: 30px;
                                opacity: 0.5;
                            }
                        }

                        .right {
                            .main-text {
                                color: $black;
                                padding: 5px 0;
                                text-transform: uppercase;
                            }
                            .regular-text {
                                color: $black;
                                padding: 5px 0;
                                opacity: 0.8;
                            }
                        }
                    }
                `}</style>
            </div>
        )

        setMarkerPopupContent(content)
    }, [])

    return (
        <div className="cblock__fullimg">
            <div id="googlemap_schools" style={{ width: '100%', height: 700 }}>
                <MapAdapter defaultZoom={12} defaultCenter={location} height={700}>
                    <MarkerWithLabel position={location} labelAnchor={{ x: 60, y: 0 }} icon={'/_img/star-solid.svg'}>
                        <div
                            style={{
                                fontSize: '0.65rem',
                                padding: '3px',
                                color: '#000',
                                backgroundColor: '#fff',
                                maxWidth: 120,
                                fontWeight: 700,
                                textAlign: 'center',
                            }}
                        >
                            {address}
                        </div>
                    </MarkerWithLabel>

                    {markerPopupVisible && (
                        <InfoWindow
                            onCloseClick={(e) => {
                                setMarkerPopupVisible(false)
                            }}
                            positon={location}
                            defaultPosition={markerPopupLocation}
                            key={`${JSON.stringify(markerPopupLocation)}`}
                        >
                            {markerPopupContent}
                        </InfoWindow>
                    )}

                    {markers?.map((item, i) => {
                        return (
                            <MarkerWithLabel
                                key={`${item.name}${i}`}
                                position={item.location}
                                labelAnchor={{ x: 10, y: 0 }}
                                icon={'missed_icon_workaround'}
                                onMouseOver={(e) => {
                                    setMarkerPopupLocation(item.location)
                                    setMarkerPopupData(item)
                                    setMarkerPopupVisible(true)
                                    setHighlightedIndex && setHighlightedIndex(i)
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: 3,
                                        fontSize: '0.65rem',
                                        padding: '4px 8px',
                                        color: '#fff',
                                        backgroundColor: '#4485ff',
                                        maxWidth: 120,
                                        fontWeight: 700,
                                        textAlign: 'center',
                                    }}
                                >
                                    {i + 1}
                                </div>
                            </MarkerWithLabel>
                        )
                    })}
                </MapAdapter>
            </div>
        </div>
    )
}
