type Location = {
    lat: number
    lng: number
}

export const getDistanceInMiles = (first: Location, second: Location): number => {
    if (!first || !second || (first.lat == second.lat && first.lng == second.lng)) return 0

    const radlat1 = (Math.PI * first.lat) / 180
    const radlat2 = (Math.PI * second.lat) / 180
    const theta = first.lng - second.lng
    const radtheta = (Math.PI * theta) / 180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
        dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    return dist
}
