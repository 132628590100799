import React, { FunctionComponent, SyntheticEvent } from 'react'

type Props = {
    showMore: (e: SyntheticEvent) => void
    showLess: (e: SyntheticEvent) => void
    showMoreVisible: boolean
    showLessVisible: boolean
}

export const ShowMoreButton: FunctionComponent<Props> = (props: Props) => {
    const { showMore, showLess, showMoreVisible, showLessVisible } = props
    return (
        <div className="col-md-12 d-flex justify-content-center px-1">
            {showMoreVisible && (
                <a
                    type="button"
                    className="btn-dark repeater-add-btn mx-2"
                    style={{
                        color: '#5086fb',
                    }}
                    onClick={showMore}
                >
                    <i className="material-icons-outlined">{'add_circle_outline'}</i>
                    <span className="">{'Show More'}</span>
                </a>
            )}
            {showLessVisible && (
                <a
                    type="button"
                    className="btn-dark repeater-add-btn  mx-2"
                    style={{
                        color: '#5086fb',
                    }}
                    onClick={showLess}
                >
                    <i className="material-icons-outlined">{'remove_circle_outline'}</i>
                    <span className="">{'Show Less'}</span>
                </a>
            )}

            {/*language=scss*/}
            <style jsx>
                {`
                    @import './src/scss/colors.scss';
                    .material-icons {
                        font-family: 'Material Icons', serif;
                        font-weight: normal;
                        font-style: normal;
                        font-size: 24px;
                        line-height: 1;
                        letter-spacing: normal;
                        text-transform: none;
                        display: inline-block;
                        white-space: nowrap;
                        word-wrap: normal;
                        direction: ltr;
                        -webkit-font-feature-settings: 'liga';
                        -webkit-font-smoothing: antialiased;
                    }

                    .material-icons-outlined {
                        font-family: 'Material Icons Outlined', serif;
                        font-weight: normal;
                        font-style: normal;
                        font-size: 24px;
                        line-height: 1;
                        letter-spacing: normal;
                        text-transform: none;
                        display: inline-block;
                        white-space: nowrap;
                        word-wrap: normal;
                        direction: ltr;
                        -webkit-font-feature-settings: 'liga';
                        -webkit-font-smoothing: antialiased;
                    }
                `}
            </style>
        </div>
    )
}
